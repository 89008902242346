.about-us {
  font-family: Arial, sans-serif;
  color: #333;
}

/* Intro Section */
.about-intro {
  position: relative; /* Required for positioning the pseudo-element */
  background: #4a148c url('/src/assets/Bg/welcome_bg.jpg') no-repeat center center;
  background-size: cover;
  color: #fff;
  text-align: center;
  padding: 20px 0;
  height: 400px;
  z-index: 1; /* Ensure content stays above the overlay */
  display: flex; /* Enable flexbox */
  flex-direction: column; /* Stack items vertically */
  justify-content: center; /* Center items vertically */
  align-items: center; /* Center items horizontally */
}
.about-intro::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(74, 20, 140, 0.7); /* Overlay color with transparency */
  z-index: -1; /* Place the overlay behind the content */
}

.about-intro h1 {
  font-size: 36px;
  margin-top: 150px;
  margin-bottom: 20px;
}

.about-intro .about-intro-text {
  font-size: 18px;
  color: #ccc;
}

/* Overview Section */
.about-overview-section {
  padding: 50px 0;
  background-color: #fff;
}

.about-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

.about-row {
  display: flex;
  align-items: center;
  margin-bottom: 50px;
  gap: 30px; /* Add spacing between text and image columns */
}

.about-row.reverse {
  flex-direction: row-reverse; /* Reverse the layout for alternating rows */
}

.about-col {
  flex: 1;
}

.about-text-column {
  padding: 20px;
}

.about-text-column h2 {
  font-size: 28px;
  margin-bottom: 15px;
}

.about-text-column p {
  font-size: 16px;
  line-height: 1.6;
  color: #333;
}

.image-column {
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-column img {
  max-width: 70%;
  height: auto;
 
}

/* Adjustments for Alternating Rows */
.about-row.reverse {
  flex-direction: row-reverse; /* Reverse layout for alternating rows */
}


/* Responsive Design */
@media (max-width: 991px) {
  .about-row {
    flex-direction: column; /* Stack image and text vertically */
  }

  .about-row.reverse {
    flex-direction: column; /* Stack reversed rows vertically */
  }

  .about-text-column {
    text-align: center;
    padding: 20px 0;
  }

  .image-column img {
    max-width: 90%; /* Slightly reduce image size on medium screens */
  }
}

@media (max-width: 768px) {
  .about-intro h1 {
    font-size: 28px;
  }

  .about-intro .about-intro-text {
    font-size: 16px;
  }

  .about-text-column h2 {
    font-size: 24px;
  }

  .about-text-column p {
    font-size: 14px;
  }

  .image-column img {
    max-width: 80%;
  }
}

@media (max-width: 576px) {
  .about-intro h1 {
    font-size: 24px;
  }

  .about-intro .about-intro-text {
    font-size: 14px;
  }

  .about-text-column h2 {
    font-size: 20px;
  }

  .about-text-column p {
    font-size: 12px;
  }

  .image-column img {
    max-width: 100%; /* Ensure the image fills the available width */
  }
}
