  /* Preloader Styling */
  .digimax-preloader {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
    z-index: 9000;
  }
  
  .digimax-preloader .preloader-animation {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .preloader-animation .spinner {
    height: 9em;
    width: 9em;
    margin-bottom: 3.5em;
    border: 3px solid rgba(0, 0, 0, 0.2);
    border-top-color: #000000;
    border-radius: 50%;
    animation: spinner 1s infinite linear;
  }
  
  .preloader-animation .loader {
    font: bold 5em "Poppins", sans-serif;
    text-align: center;
    user-select: none;
  }
  
  .preloader-animation .loader .animated-letters {
    position: relative;
    color: rgba(0, 0, 0, 0.2);
  }
  
  .loader .animated-letters:before {
    position: absolute;
    content: attr(data-text-preloader);
    top: -3px;
    left: 0;
    color: #000000;
    opacity: 0;
    transform: rotateY(-90deg);
    animation: animated-letters 4s infinite;
  }
  
  .loader .animated-letters:nth-child(2):before {
    animation-delay: 0.2s;
  }
  
  .loader .animated-letters:nth-child(3):before {
    animation-delay: 0.4s;
  }
  
  .loader .animated-letters:nth-child(4):before {
    animation-delay: 0.6s;
  }
  
  .loader .animated-letters:nth-child(5):before {
    animation-delay: 0.8s;
  }
  
  .loader .animated-letters:nth-child(6):before {
    animation-delay: 1s;
  }
  
  .loader .animated-letters:nth-child(7):before {
    animation-delay: 1.2s;
  }
  
  .loader .animated-letters:nth-child(8):before {
    animation-delay: 1.4s;
  }
  
  .digimax-preloader p {
    font-family: "Poppins", sans-serif;
    letter-spacing: 8px;
    color: #3b3b3b;
  }
  
  .digimax-preloader .loader-animation {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    font-size: 0;
    z-index: 1;
    pointer-events: none;
  }
  
  .single-loader .loader-bg {
    height: 100%;
    width: 100%;
    left: 0;
    background-color: #ffffff;
    transition: all 800ms cubic-bezier(0.77, 0, 0.175, 1);
  }
  
  .digimax-preloader.loaded .preloader-animation {
    opacity: 0;
    transition: 0.3s ease-out;
  }
  
  .digimax-preloader.loaded .single-loader .loader-bg {
    width: 0;
    transition: 0.7s all cubic-bezier(0.1, 0.1, 0.1, 1);
  }
  
  @keyframes spinner {
    to {
      transform: rotateZ(360deg);
    }
  }
  
  @keyframes animated-letters {
    0%, 75%, 100% {
      opacity: 0;
      transform: rotateY(-90deg);
    }
    25%, 50% {
      opacity: 1;
      transform: rotateY(0deg);
    }
  }
  
  @media screen and (max-width: 767px) {
    .preloader-animation .spinner {
      height: 8em;
      width: 8em;
    }
    .preloader-animation .loader {
      font: bold 3.5em "Poppins", sans-serif;
    }
  }
  
  @media screen and (max-width: 500px) {
    .preloader-animation .spinner {
      height: 7em;
      width: 7em;
    }
    .preloader-animation .loader {
      font: bold 2em "Poppins", sans-serif;
    }
  }
  /* Media Queries */
  @media screen and (max-width: 767px) {
    .preloader-animation .spinner {
      height: 8em;
      width: 8em;
    }
    .preloader-animation .loader {
      font: bold 3.5em "Poppins", sans-serif;
    }
  }
  
  @media screen and (max-width: 500px) {
    .preloader-animation .spinner {
      height: 7em;
      width: 7em;
    }
    .preloader-animation .loader {
      font: bold 2em "Poppins", sans-serif;
    }
  }