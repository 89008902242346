

/* Footer Container */
.footer-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 40px;
    background: #ffffff;
    color: rgb(0, 0, 0);
    flex-wrap: wrap;
  }
  
  /* Footer Branding */
  .footer-branding {
    flex: 1;
    margin-right: 20px;
  }
  
  .footer-branding h2 {
    font-size: 24px;
    font-weight: bold;
    color: #007bff;
    margin-bottom: 10px;
  }
  
  .footer-branding p {
    font-size: 14px;
    line-height: 1.6;
  }
  /* Footer Links */
  .footer-links {
    flex: 1;
    margin-right: 20px;
  }
  
  .footer-links h3 {
    font-size: 18px;
    margin-bottom: 10px;
    color: #ff6b6b;
  }
  
  .footer-links ul {
    list-style: none;
    padding: 0;
  }
  
  .footer-links ul li {
    margin-bottom: 8px;
  }
  
  .footer-links ul li a {
    text-decoration: none;
    color: rgb(0, 0, 0);
    transition: color 0.3s ease;
  }
  
  .footer-links ul li a:hover {
    color: #007bff;
    
  }
  
  /* Footer Contact */
  .footer-contact {
    flex: 1;
    margin-right: 20px;
  }
  
  .footer-contact h3 {
    font-size: 18px;
    margin-bottom: 10px;
    color: #ff6b6b;
  }
  
  .footer-contact p {
    margin: 0 0 8px;
    font-size: 14px;
  }
  
  /* Footer Social */
  .footer-social {
    flex: 1;
    
  }
  
  .footer-social h3 {
    font-size: 18px;
    margin-bottom: 10px;
    color: #ff6b6b;
  }
  

  
  /* Responsive Design */
  @media (max-width: 768px) {
    .footer-container {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .footer-branding,
    .footer-links,
    .footer-contact,
    .footer-social {
      margin-bottom: 20px;
    }
  }
  
  .footsocial-icons {
    display: flex;
    gap: 10px;
    
  }
  
  .footsocial-icons a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background: #007bff;
    border-radius: 50%;
    color: white;
    font-size: 18px;
    transition: background 0.3s ease;
    
  }
  
  .footsocial-icons a:hover {
    background: #6b5ce7;
    color: white;
    transform: scale(1.1);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  /* Footer Bar Styles */
  .foot-bar {
    background-color: #e4e4e49f; /* Dark background */
    color: #2b2b2b; /* White text color */
    padding: 10px 120px; /* Padding for spacing */
    display: flex; /* Flexbox for alignment */
    justify-content: space-between; /* Space between left and right content */
    align-items: center; /* Vertically center items */
    font-family: Arial, sans-serif; /* Font style */
    font-size: 14px; /* Font size */
    
  }
  
  .foot-content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .foot-left {
    margin-left: 10px; /* Add some gap on the left */
  }
  
  .foot-right a {
    color: #00bcd4; /* Highlighted link color */
    text-decoration: none; /* Remove underline */
    font-weight: bold; /* Bold text for link */
  }
  
  .foot-right a:hover {
    text-decoration: underline; /* Underline on hover */
  }
  
  .heart-icon {
    color: red; /* Red color for heart */
    font-size: 16px; /* Slightly larger heart */
    margin: 0 5px; /* Add spacing around the heart */
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .foot-bar {
      flex-direction: column; /* Stack content vertically on smaller screens */
      text-align: center; /* Center text */
      padding: 10px; /* Add more padding for mobile devices */
    }
  
    .foot-content {
      flex-direction: column; /* Stack content vertically */
      justify-content: center; /* Center items */
      align-items: center; /* Align items in the center */
    }
  
    .foot-left,
    .footer-right {
      margin: 5px 0; /* Add margin between sections */
    }
  
    .foot-left {
      margin-left: 0; /* Remove left margin on smaller screens */
    }
  }
  