.ourwork-header {
    position: relative; /* Required for positioning the pseudo-element */
    background: #4a148c url('/src/assets/Bg/welcome_bg.jpg') no-repeat center center;
    background-size: cover;
    color: #fff;
    text-align: center;
    padding: 20px 0;
    height: 400px;
    z-index: 1; /* Ensure content stays above the overlay */
    display: flex; /* Enable flexbox */
    flex-direction: column; /* Stack items vertically */
    justify-content: center; /* Center items vertically */
    align-items: center; /* Center items horizontally */
}
.ourwork-header h1{
    font-size: 60px;
    font-family:Arial, Helvetica, sans-serif;
}
.ourwork-header p {
    position: relative;
    bottom: 30px; /* Move the paragraph down */
    font-size: 16px;
    color: #ddd;
}

.ourwork-header::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(74, 20, 140, 0.7); /* Overlay color with transparency */
    z-index: -1; /* Place the overlay behind the content */
}