/* Internship List Section */
.internship-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 50px 20px;
  justify-content: center;
  animation: fadeIn 1s ease-out;
}

.internship-item {
  background-color: #fff;
  width: 300px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
  opacity: 0;
  transform: translateY(20px);
  animation: slideIn 0.8s forwards;
}

@keyframes slideIn {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.internship-item:nth-child(even) {
  animation-delay: 0.3s;
}

.internship-item:nth-child(odd) {
  animation-delay: 0.5s;
}

.internship-item:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
  background-color: #f9f9f9;
}

.internship-item-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-bottom: 5px solid #4a148c;
}

.internship-item-content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.internship-item-title {
  font-size: 1.4em;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.internship-item-description {
  font-size: 1em;
  color: #777;
  margin-bottom: 15px;
}

.internship-apply-btn {
  padding: 10px 20px;
  background-color: #4a148c;
  color: white;
  text-decoration: none;
  border-radius: 25px;
  font-size: 1.1em;
  text-align: center;
  margin-top: 10px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.internship-apply-btn:hover {
  background-color: #6a1b9a;
  transform: scale(1.05);
}

/* Responsive Design */
@media (max-width: 1024px) {
  .internship-item {
    width: 45%;
  }
}

@media (max-width: 768px) {
  .internship-item {
    width: 70%;
  }
}

@media (max-width: 480px) {
  .internship-item {
    width: 100%;
  }
}


.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  width: 80%;
  max-width: 400px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-header h2 {
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

.close-modal-btn {
  background: none;
  border: none;
  font-size: 20px;
  color: #333;
  cursor: pointer;
}

.modal-body {
  margin-top: 15px;
  font-size: 16px;
  color: #555;
}


